import React from "react";
import Navbar from "./Navbar";
import "./about.css";
export const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="classic-page">
        <main className="main-content">
          <section className="about-section">
            <h2>About Classic</h2>
            <p>
              Established in 1994, Classic is a leading electronics retailer
              offering more than 150 leading brands under one roof. Its range of
              consumer electronics & home appliances are available through its
              store and website.
            </p>
            <p>
              Classic delivery service is available across all of Guwahati,
              seven days a week, and offers complimentary home delivery in as
              little as three hours up to 9pm.
            </p>
            <p>
              You can also order online and pick up your item(s) at the store
              for FREE.
            </p>
          </section>
        </main>
      </div>
    </>
  );
};
