import React, { useEffect } from "react";
import "./allStyle.css";
import Navbar from "../../pages/Navbar";

function Privacy() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <Navbar />

      <div className="classic-page">
        <main className="main-content">
          <section className="privacy-section">
            <h2>Privacy Policy</h2>
            <p>
              We value the trust you place in Classic Guwahati, that's why we
              insist upon the highest standards for secure transactions and
              customer information privacy. Please read the following statement
              to learn about our information gathering and dissemination
              practices.
            </p>
            <p>
              By mere use of www.classicguwahati.com you expressly consent to
              our use and disclosure of your personal information in accordance
              with this Privacy Policy. This Privacy Policy is incorporated into
              and subject to the terms of the user agreement.
            </p>
            <p>
              www.classicguwahati.com respects your privacy. This Privacy Policy
              provides succinctly the manner your data is collected and used by
              www.valueplusretail.com you are advised to please read the Privacy
              Policy carefully. By accessing the services provided by Classic,
              you agree to the collection and use of your data by Classic in the
              manner provided in this Privacy Policy.
            </p>
            <div className="privacy-goals">
              <h3>We want you to:</h3>
              <ul>
                <li>Feel comfortable using our website.</li>
                <li>Feel secure submitting information to us.</li>
                <li>
                  Contact us with your questions or concerns about privacy on
                  this site.
                </li>
                <li>
                  Know that by using our sites you are consenting to the
                  collection of certain data.
                </li>
              </ul>
            </div>
            <h3>General Principles</h3>
            <p>
              Protecting your privacy is very important to us. We have
              accordingly developed this Privacy Policy to protect your personal
              information and keep it confidential. We endeavour to comply with
              laws of other countries but cannot and do not warrant that we do.
            </p>
            <h3>
              We categorize information about you (collectively referred to as
              "Personal Information") as follows:
            </h3>
            <p>
              Profiling Information: Information which you provide when you
              register for a Service, which may include some or all of the
              following: - Information about your personal identity such as
              gender, marital status, age, preferences, likes and dislikes etc.;
              - Your financial information such as your banking details and any
              information relating to your income and lifestyle levels; and -
              Your contact details such as your physical addresses, postal
              addresses, telephone and fax numbers and the like. - In the case
              of Facebook aligned services (if so opted by you), publicly
              available information of your friends, their likes and dislikes,
              etc.
            </p>
            <p>
              Payment and Account Information: Your account history with us
              including (without limitation) all billing information and
              communications, payment history etc. We maintain this in encrypted
              form on secure servers.{" "}
            </p>
            <p>
              Service Usage: Information about your navigation using our
              Services, for example the URLs of websites, which you visit and
              from which you request downloads.{" "}
            </p>
            <p>
              {" "}
              Log information: Information such as your web request, IP address,
              browser type, browser language, date and time of request.
            </p>
            <p>
              Transactional Information: Transactional history (other than
              banking details) about your e-commerce activities.{" "}
            </p>
            <p>
              Correspondence Information: Content, information about your
              correspondents, and the destination/origin of communications
              between you and any other person using our Services, which include
              email communications, blog, chat room and discussion board
              communications, instant message communications, expert’s forum
              communications, fax mail communications, membership of mailing
              lists etc.{" "}
            </p>
            <p>
              {" "}
              User IDs: Your usernames, passwords, email addresses and other
              security-related information used by you in relation to our
              Services.
            </p>
            <p>
              Stored Information: Data either created by you or by a third party
              and which you wish to store on our servers such as image files,
              documents etc. We only collect your Personal Information to
              conduct our business and to enable us to deliver and improve our
              Services. We do not for any reason whatsoever sell your Personal
              Information to any third party or otherwise trade on it. If we
              want to access your personal information for any other purpose, we
              will obtain your prior written consent. Any of your information
              which you provide when you use certain services and are to an
              open, public environment or forum including (without limitation)
              any blog, community or discussion board, is not confidential, does
              not constitute Personal Information, and is not subject to
              protection under Privacy Policy. Since such public environments
              are accessible by third parties, it is possible that third parties
              may collect and collate and use such information for their own
              purposes. You should accordingly be careful when deciding to share
              any of your Personal Information in such public environments.
              Information, which is disclosed publicly, is also shared with our
              affiliates, third party service providers, sponsors of
              competitions etc. unless expressly stated otherwise. We are not
              liable to you or any third party for any damages that you or any
              third party may suffer howsoever arising from your disclosure of
              Personal Information in any public environment. You accordingly
              disclose information in a public environment at your own risk.{" "}
            </p>
            <h3>CLASSIC's Privacy Policy for The Internet</h3>
            <p>
              Classic is and attempts to be sensitive to your privacy on the
              Internet. Whenever possible, Classic will attempt to treat the
              information about you we receive on the Internet with care deemed
              reasonable under the circumstances. Value Plus uses information
              provided by you (if any) to measure the use of our site and to
              improve the content of our site. The personal information we may
              collect is used only by us to respond to your inquiry, process an
              order or allow you to access specific account information. At
              times cookies may be used to provide you with certain information.
              A cookie is a tiny element of data that a web site can send to
              your browser, which may then be stored on your hard drive so we
              can recognize you when you return. You may set your browser to
              notify you when you receive a cookie.{" "}
            </p>
            <p>
              {" "}
              We will collect anonymous traffic information from you when you
              visit our site. We will collect personally identifiable
              information about you only as part of a voluntary registration
              process, on-line survey, or contest or any combination thereof.
              Our advertisers may collect anonymous traffic information from
              their own assigned cookies to your browser. The Site contains
              links to other Web sites. We are not responsible for the privacy
              practices of such Web sites which we do not own, manage or
              control.
            </p>
            <h3>
              We collect and store your Service Usage and Transactional
              Information to:
            </h3>
            <p>
              {" "}
              Determine and verify the Service Charges payable by you and to
              administer our relationship with you. Comply with any statutory or
              regulatory requirement.
            </p>
            <p>
              Compile statistical and demographical profiles about you for our
              business and marketing activities and to customize our Services to
              you. While we are entitled to use such information about you for
              our own internal business purposes without limitation, we will
              only disclose it in an aggregated form which is not capable of
              being used or interpreted in such a manner as to identify you{" "}
            </p>
            <h3>
              We collect and store your Correspondence Information and Personal
              Identifiers to:
            </h3>
            <p>Comply with our obligations under law </p>
            <p>
              {" "}
              Monitor your use of our Services in order to ensure your
              compliance with our Terms of Service. Any Personal Information
              which we collect and which we may use in an aggregated format
              ensuring you are not individually identified is our property. We
              may use it, in our sole discretion and without any compensation to
              you, for any legitimate purpose including (without limitation) the
              commercial sale thereof to third parties. Sometime we use
              "cookies" so that we can provide you with more customized
              information when you return to our website. "Cookies" are used to
              store user preferences and to track user trends, so as to enhance
              your interactive experience and generally improve our Services to
              you. You can set your browser to notify you when you are sent a
              "cookie", giving you the chance to decide whether or not to accept
              it. If you do accept a "cookie", you thereby agree to our use of
              any Personal Information collected by us using that Cookie. You
              may update your Profiling Information at any time on the website.
              You acknowledge and agree that in the interests of improving
              personalization and Service efficiency, we may, under controlled
              and secure circumstances, share your Personal Information with our
              affiliates (an entity which is our subsidiary or holding company
              or a subsidiary of our holding company or an entity which
              controls, is controlled by or is under common control with us).
            </p>
            <h3>
              What security procedures are in place to protect information from
              loss, misuse or alteration?
            </h3>
            <p>
              To protect against the loss, misuse and alteration of the
              information under our control, we have in place appropriate
              physical, electronic and managerial procedures. For example, our
              servers are accessible only to authorized personnel and that your
              information is shared with respective personnel on need to know
              basis to complete the transaction and to provide the services
              requested by you.{" "}
            </p>
            <p>
              {" "}
              Although we will endeavour to safeguard the confidentiality of
              your personally identifiable information, transmissions made by
              means of the Internet cannot be made absolutely secure. No method
              of transmission over the internet, or method of electronic
              storage, is 100% secure, however. Therefore, while we strive to do
              so, we cannot guarantee its absolute security. Further, other than
              as specifically stated in this Privacy Policy, the information
              collected may in the sole discretion of the company be disclosed,
              in the event it is required to do so by any law, rules,
              regulations, statutory or legal authority or judicial orders etc.
              as it deems it necessary or as may be required .You also agree and
              acknowledge that the company shall not be responsible in the event
              of any breach of security or any for any actions of any third
              party or event that are beyond the reasonable control of the
              company.
            </p>
            <p>
              The Company may use social sharing buttons which helps to share
              web content directly from web pages to the platform in question.
              The user is advised before using such social sharing buttons that
              user does so at user’s own discretion. Social media and other
              platforms may track and save user's request to share a web page
              respectively through user's social media platform and other
              accounts. By using this site, you agree that we will have no
              liability for disclosure of your information due to errors in
              transmission or unauthorized acts of third parties.
            </p>
            <h3>Policy updates</h3>
            <p>
              We reserve the right to change the terms of this privacy policy at
              any time. We may update this privacy policy at any time, with or
              without advance notice. We encourage you to review this privacy
              policy whenever you visit our website. Such changes shall be
              effective immediately upon posting to this site.{" "}
            </p>
            <h3>Grievances</h3>
            <p>
              {" "}
              In the event of any grievances, user can contact us at
              classic.ghy@gmail.com or write to us at the following address;{" "}
            </p>
            <p>Classic </p>
            <p> 136, Maniram Dewan Road, Chandmari, Guwahati – 781003</p>
          </section>
        </main>
      </div>
    </div>
  );
}

export default Privacy;
