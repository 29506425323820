import React, { useEffect } from "react";
import "./allStyle.css";
import Navbar from "../../pages/Navbar";

function Refund() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <Navbar />

      <div className="classic-page">
        <main className="main-content">
          <section className="refund-policy-section">
            <h2>Refund/Cancellation Policy</h2>
            <p>
              Returns is a scheme provided directly under this policy in terms
              of which the option of exchange, replacement and/ or refund is
              offered. All products listed under a particular category may not
              have the same returns policy. For all products, the
              returns/replacement policy provided on the product page shall
              prevail over the general returns policy. Do refer the respective
              item's applicable return/replacement policy on the product page
              for any exceptions to this returns policy.
            </p>
            <p>
              Return & Refund Policy: There could be certain circumstances
              beyond our control where you could receive a damaged / defective
              product or a product that is not the same as per your original
              order. We will replace the product or refund the amount in such
              cases. Our Returns Policy covers all these situations. You can
              write to us at our Customer Service number at Guwahati: 9864038599
              (10 AM - 9 PM) The return process of the product can be restricted
              depending on the nature and category of the product.
            </p>

            <h3>Conditions for return:</h3>
            <ul>
              <li>
                Please notify us of receipt of a Damaged / Defective product
                within maximum 48 hours of delivery.
              </li>
              <li>Products/Items should be UNUSED.</li>
              <li>
                Products should be returned in their original packaging along
                with the original price tags, labels and invoices.
              </li>
              <li>
                It is advised that the return packets should be strongly and
                adequately packaged so that there is no further damage of goods
                in transit.
              </li>
            </ul>

            <h3>Refunds:</h3>
            <ul>
              <li>
                We will process the refund after receipt of the product by PM
                International or its business partner.
              </li>
              <li>
                Refund will be processed depending on the mode of payment of the
                order:
              </li>
              <li>
                Orders paid by credit/ debit card will be refunded by credit
                back to the credit/ debit card within 7 working days and the
                refund will reflect in the next statement.
              </li>
              <li>
                Orders paid by net banking accounts will be credited back to
                bank account.
              </li>
              <li>
                For all other modes of payment, we will send a refund cheque.
                The cheque will be made in favor of the name as in the "billing
                name" provided at the time of placing the order.
              </li>
            </ul>
          </section>
        </main>
      </div>
    </div>
  );
}

export default Refund;
