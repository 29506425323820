import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
export default function FooterMain() {
  return (
    <div>
      {/* <!-- Site footer --> */}
      <footer class="site-footer">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <h1 id="logo-title" style={{ color: "white" }}>
                CLASSIC
              </h1>
              <p class="text-justify" style={{ color: "white" }}>
                A House of consumer electronics & appliances
              </p>
            </div>

            <div class="col-xs-6 col-md-3">
              <h6>About Us</h6>
              <ul class="footer-links">
                <li>
                  <Link to={"/about"}>
                    <span className="link-item">About Classic</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/contact"}>
                    <span className="link-item">Contact Us</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/privacy"}>
                    <span className="link-item">Privacy Policy</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/term/conditions"}>
                    <span className="link-item">Terms and Conditions</span>
                  </Link>
                </li>

                <li>
                  <Link to={"/refund/cancellation"}>
                    <span className="link-item">
                      Refund/Cancellation Policy
                    </span>
                  </Link>
                </li>

                {/* <li>
                  <Link to={"/shipping"}>
                    <span href="/about" className="link-item">
                      Fees Details/Price details
                    </span>
                  </Link>
                </li> */}
              </ul>
            </div>

            <div class="col-xs-6 col-md-3">
              <h6>Customer Care</h6>
              <ul class="footer-links">
                <li>
                  <Link to={"/user/account"}>
                    <span className="link-item">My Account</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/user/account"}>
                    <span className="link-item">Track your order</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/store"}>
                    <span className="link-item">Store</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <hr />
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-12">
              <p class="copyright-text">
                Copyright &copy; 2023 All Rights Reserved by{" "}
                <a href="#" style={{ color: "white" }}>
                  CLASSIC
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
