import React from "react";
import "./allStyle.css";
import Navbar from "../../pages/Navbar";
function ContactUS() {
  return (
    <div>
      <Navbar />
      <div className="classic-page">
        <main className="main-content">
          <section className="contact-section">
            <h2>Contact Us</h2>
            <p>
              Our team is ready to assist you. To ensure that your enquiry is
              directed to the appropriate department, kindly choose the option
              that best describes your needs:
            </p>
            <div className="contact-info">
              <h3>To place an order:</h3>
              <p>Call us on 8453925376 or Whatsapp on +44 7476110382</p>
              <p>Opening hours: Monday -- Sunday: 10am to 9pm</p>

              <h3>For Returns & Refunds:</h3>
              <p>Call us on 8638380074</p>
              <p>Opening hours: Monday -- Sunday: 10am to 9pm</p>

              <h3>For anything else:</h3>
              <p>Call us on 9864038599</p>
              <p>Opening hours: Monday -- Sunday: 11am to 8pm</p>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}

export default ContactUS;
