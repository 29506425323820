import "./App.css";
import Home from "./pages/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { useStateContext } from "./contextApi/StateContext";
import { Toaster } from "react-hot-toast";
import Modal from "react-modal";
import "react-loading-skeleton/dist/skeleton.css";
import "react-multi-carousel/lib/styles.css";
import { useEffect, lazy, Suspense } from "react";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Loading from "./components/Loading/index";
import About, { AboutPage } from "./pages/About";
import Aos from "aos";
import TermCondition from "./components/OtherSection/TermCondition";
import Privacy from "./components/OtherSection/Privacy";
import Refund from "./components/OtherSection/Refund";
import Contact from "./components/Contact";
import ContactUS from "./components/OtherSection/Contact";

const ProductCategoryDetails = lazy(() =>
  import("./pages/ProductCategoryDetails")
);
const ProductDetails = lazy(() => import("./pages/ProductDetails"));
const Cart = lazy(() => import("./pages/Cart"));
const Buy = lazy(() => import("./pages/Buy"));
const Store = lazy(() => import("./pages/Store"));
const Wishlist = lazy(() => import("./pages/Wishlist"));
const Login = lazy(() => import("./pages/Login"));
const Account = lazy(() => import("./pages/Account"));
const OrderDetail = lazy(() => import("./pages/OrderDetail"));
const TrackOrder = lazy(() => import("./pages/TrackOrder"));
const NotFound = lazy(() => import("./pages/NotFoundPage"));

Modal.setAppElement("#root");
Aos.init({
  offset: 200,
  duration: 600,
  easing: "ease-in-sine",
  delay: 100,
});
function App() {
  const { user, setUser, getAllProduct, getWishlist } = useStateContext();
  useEffect(() => {
    const us = localStorage.getItem("user");
    setUser(Boolean(us));
    getAllProduct();
    getWishlist();
  }, []);
  return (
    <Router>
      <Toaster
        position="right-top"
        containerStyle={{ zIndex: "999999999999999999999999999999999" }}
        reverseOrder={false}
      />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/product/:name" element={<ProductCategoryDetails />} />
          <Route
            path="/productDetails/:productId"
            element={<ProductDetails />}
          />
          <Route path="/cart" element={<Cart />} />
          <Route path="/store" element={<Store />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/product/checkout" element={<Buy />} />
          <Route path="/user/account" element={<Account />} />

          <Route path="/order/success/:orderId" element={<OrderDetail />} />
          <Route path="/order/details/:ordernumber" element={<TrackOrder />} />
          <Route
            path="/product/search/:searchname"
            element={<ProductCategoryDetails />}
          />
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/term/conditions" element={<TermCondition />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/refund/cancellation" element={<Refund />} />
          <Route path="/contact" element={<ContactUS />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
